<template>
  <b-card-actions
      ref="card-actions"
      class="actions-card"
      @refresh="refreshStop('card-actions')"
  >
    <b-row class="mt-50">
      <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
        <b-col cols="12">
          <h4 class="text-white d-inline-block card-title">Proforma Fatura Kayıtları</h4>
          <b-button
              v-if="width > 500"
              variant="flat-primary"
              class="text-white float-right bg-primary bg-lighten-2 card-right-button"
              to="/yeniProforma"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton+'!important',border:'none'}"
          >
            <font-awesome-icon icon="fa-solid fa-plus"/>
            Yeni Proforma Fatura
          </b-button>
        </b-col>
      </div>
    </b-row>
    <b-row class="mt-5" v-if="width < 500">
      <b-col cols="12">
        <b-button
            variant="flat-primary"
            class="text-white float-right bg-primary bg-lighten-2 card-right-button"
            to="/yeniProforma"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton+'!important',border:'none'}"
        >
          <font-awesome-icon icon="fa-solid fa-plus"/>
          Yeni Proforma Fatura
        </b-button>
      </b-col>
    </b-row>
    <b-tabs class="mt-4" :class="width < 500 ? 'mt-5':''">
      <b-tab title="Bekleyen Proformalar">
        <b-row>
          <b-col lg="6" md="6" sm="12" cols="12" clas="d-flex justify-content-start">
            <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
              <b-input-group
                  size="sm"
                  prepend="Gösterilecek kayıt sayısı"
                  class="unPrint per-page"
              >
                <b-form-select
                    v-model="firstPerPage"
                    :options="firstPageOptions"
                    size="sm"
                    @change="firstPerPageChange($event)"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" cols="12" class="d-flex justify-content-end">
            <b-input-group size="sm" class="search-bar" :style="{width: width < 1300 ? '60%!important':''}">
              <b-input-group-prepend>
                <b-button
                    variant="outline-primary"
                    size="sm"
                    disabled
                    class="search-input mb-0"
                >
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input id="filterInput" v-model="firstFilter" type="search"/>
            </b-input-group>
          </b-col>
        </b-row>
        <hr/>
        <b-table
            striped
            small
            hover
            responsive
            class="position-relative cari-group-table mt-1"
            :items="bekleyenFaturalar"
            :fields="fields"
            :per-page="firstPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter-included-fields="filterOn"
            @filtered="FirstOnFiltered"
            @row-clicked="goFatura"
        >
          <template #cell(faturaUnvan)="data">
            <strong class="text-dark fatura-notu" v-if="data.item.aciklama"
                    v-b-tooltip.hover.top="data.item.aciklama"
            >
              {{
                data.item.aciklama.slice(0, 50)
              }}{{ data.item.aciklama.length > 40 ? '...' : '' }}
            </strong>
            <p class="mb-0" v-if="data.item.faturaUnvan">
              {{ data.item.faturaUnvan.slice(0, 70) }}
              {{ data.item.faturaUnvan > 60 ? '...' : '' }}
            </p>
            <p class="mb-0" v-else>
              {{
                (data.item.adi, data.item.soyAdi).slice(0, 70)
              }}{{ data.item.adi.length + data.item.soyAdi.length > 60 ? '...' : '' }}
            </p>
          </template>
          <template #cell(proformaID)="data">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="success"
                v-if="data.item.durum == 0"
                :disabled="kaydetBtn"
                @click="convertSatisFaturasi(data.item)"
                :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
            >
              <font-awesome-icon icon="fa-solid fa-right-left"/>
              Satış Faturasına Dönüştür
            </b-button>
          </template>
          <template #cell(kayitTarih)="data">
            <b-badge
                id="bakiye-badge"
                variant="light-primary"
                size="sm"
                class="float-right"
            >
              {{ data.item.kayitTarih.slice(0, 16) }}
            </b-badge>
          </template>
          <template #cell(toplamTutar)="data">
            <strong v-if="data.item.toplamTutar" class="text-nowrap">
              <h5 class="bg-transparent text-right m-0">
                {{
                  Number(data.item.toplamTutar)
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </h5>
            </strong>
          </template>
        </b-table>
        <hr/>
        <b-row class="d-flex align-items-center mt-1">
          <b-col cols="6">
            <p class="text-muted">Toplam {{ firstTotalRows }} Kayıt bulundu</p>
          </b-col>
          <b-col cols="6">
            <b-pagination
                v-model="firstCurrentPage"
                @change="firstPageChange($event)"
                :total-rows="firstTotalRows"
                :per-page="firstPerPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 float-right"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Aktarılan Proformalar" @click="aktarilanFaturaGet">
        <b-row>
          <b-col lg="6" md="6" sm="12" cols="12" clas="d-flex justify-content-start">
            <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
              <b-input-group
                  size="sm"
                  prepend="Gösterilecek kayıt sayısı"
                  class="unPrint per-page"
              >
                <b-form-select
                    v-model="secondPerPage"
                    :options="secondPageOptions"
                    size="sm"
                    @change="secondPerPageChange($event)"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" cols="12" class="d-flex justify-content-end">
            <b-input-group size="sm" class="search-bar" :style="{width: width < 1300 ? '60%!important':''}">
              <b-input-group-prepend>
                <b-button
                    variant="outline-primary"
                    size="sm"
                    disabled
                    class="search-input mb-0"
                >
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input id="filterInput" v-model="secondFilter" type="search"/>
            </b-input-group>
          </b-col>
        </b-row>
        <hr/>
        <b-table
            striped
            small
            hover
            responsive
            class="position-relative cari-group-table mt-1"
            :items="aktarilanFaturalar"
            :fields="fields"
            :per-page="secondPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter-included-fields="filterOn"
            @filtered="SecondOnFiltered"
            @row-clicked="goProforma"
        >
          <template #cell(faturaUnvan)="data">
            <strong class="text-dark fatura-notu" v-if="data.item.aciklama"
                    v-b-tooltip.hover.top="data.item.aciklama"
            >
              {{
                data.item.aciklama.slice(0, 50)
              }}{{ data.item.aciklama.length > 40 ? '...' : '' }}
            </strong>
            <p class="mb-0" v-if="data.item.faturaUnvan">
              {{ data.item.faturaUnvan.slice(0, 70) }}
              {{ data.item.faturaUnvan > 60 ? '...' : '' }}
            </p>
            <p class="mb-0" v-else>
              {{
                (data.item.adi, data.item.soyAdi).slice(0, 70)
              }}{{ data.item.adi.length + data.item.soyAdi.length > 60 ? '...' : '' }}
            </p>
          </template>
          <template #cell(proformaID)="data">
            <b-badge variant="light-primary" v-if="data.item.durum == 1"
                     v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            >
              Aktarıldı
            </b-badge>
          </template>
          <template #cell(kayitTarih)="data">
            <b-badge
                id="bakiye-badge"
                variant="light-primary"
                size="sm"
                class="float-right"
            >
              {{ data.item.kayitTarih.slice(0, 16) }}
            </b-badge>
          </template>
          <template #cell(toplamTutar)="data">
            <strong v-if="data.item.toplamTutar" class="text-nowrap">
              <h5 class="bg-transparent text-right m-0">
                {{
                  Number(data.item.toplamTutar)
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </h5>
            </strong>
          </template>
        </b-table>
        <hr/>
        <b-row class="d-flex align-items-center mt-1">
          <b-col cols="6">
            <p class="text-muted">Toplam {{ secondTotalRows }} Kayıt bulundu</p>
          </b-col>
          <b-col cols="6">
            <b-pagination
                v-model="secondCurrentPage"
                @change="secondPageChange($event)"
                :total-rows="secondTotalRows"
                :per-page="secondPerPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 float-right"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card-actions>
</template>

<script>
import {
  BTab,
  BFormSelect,
  BTabs,
  BSidebar,
  BModal,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BTab,
    BFormSelect,
    BTabs,
    BSidebar,
    BPagination,
    BButton,
    BForm,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BTable,
    BBadge,
    BRow,
    BCol,
    BInputGroupAppend,
    BInputGroupPrepend,
    BModal,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      fields: [
        {
          key: 'faturaUnvan',
          label: 'Fatura Bilgisi',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'proformaID',
          label: 'Durum',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'kayitTarih',
          label: 'Düzenlenme Tarihi',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'toplamTutar',
          label: 'Tutar',
          sortable: true,
          thStyle: {
            width: '10%',
            textAlign: 'end',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdClass: ['d-flex', 'justify-content-end'],
          tdStyle: { width: '10%' },
        },
      ],
      sortBy: '',
      kaydetBtn: false,
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      firstFilter: null,
      firstPerPage: 20,
      firstPageOptions: [5, 10, 20, 50, 100, 200, 500],
      firstToplamKayit: 1,
      firstTotalRows: 1,
      firstCurrentPage: 1,
      secondFilter: null,
      secondPerPage: 20,
      secondPageOptions: [5, 10, 20, 50, 100, 200, 500],
      secondToplamKayit: 1,
      secondTotalRows: 1,
      secondCurrentPage: 1,
    }
  },
  computed: {
    bekleyenFaturalar() {
      return store.getters.PROFORMA_LIST_GET.bekleyen
    },
    aktarilanFaturalar() {
      return store.getters.PROFORMA_LIST_GET.aktarilan
    },
  },
  watch: {
    secondFilter(newVal) {
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('proformaListesi', {
            uzunluk: this.secondPerPage,
            baslangic: 0,
            searchKey: newVal,
            durum: 1,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.data?.length
          })
    },
    firstFilter(newVal) {
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('proformaListesi', {
            uzunluk: this.firstPerPage,
            baslangic: 0,
            searchKey: newVal,
            durum: 0,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.data?.length
          })
    },
  },
  methods: {
    convertSatisFaturasi(item) {
      store.state.isProformaFatura = true
      router.push({
        name: 'proformaToFatura',
        params: { id: item.proformaID },
      })
    },
    postSchema(item) {
      const id = item.proformaOzeti.proformaID
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'fatura')
      fd.append('methodName', 'faturaKaydet')
      fd.append('faturaData[faturaTuru]', 1)
      fd.append('proformaID', id) // todo proformaID satış faturasına dönüştürmek için kullanılır
      fd.append('faturaData[cariKartID]', item.proformaOzeti.cariKartID)
      fd.append('faturaData[aciklama]', item.proformaOzeti.aciklama)
      Object.values(item.proformaBilgileri.birimData)
          .forEach((urun, index) => {
            fd.append(`faturaData[birimListesi][${index}][urunAdi]`, urun.urunAdi)
            fd.append(`faturaData[birimListesi][${index}][adet]`, urun.adet || 0)
            fd.append(`faturaData[birimListesi][${index}][birim]`, urun.birim || 'Adet')
            fd.append(`faturaData[birimListesi][${index}][tutar]`, urun.toplamTutar || 0)
            fd.append(`faturaData[birimListesi][${index}][stokID]`, urun.stokID || '')
            Object.entries(urun.vergiData.vergiListesi)
                .forEach((vergi, i) => {
                  fd.append(`faturaData[birimListesi][${index}][vergiListesi][${i}][vergiKodu]`, Object.values(vergi)[1].vergiKodu)
                  fd.append(`faturaData[birimListesi][${index}][vergiListesi][${i}][vergiOrani]`, Object.values(vergi)[1].vergiOrani || 0)
                })
          })
      return fd
    },
    eFaturaGet(faturaID) {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'fatura')
      fd.append('methodName', 'eFaturaDetay')
      fd.append('faturaID', faturaID)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            const Y = '<html>'
            const X = res.data
            var Z = X.slice(X.indexOf(Y) + Y.length)
            var myWindow = window.open(
                'about:blank',
                'print_popup',
                'width=1000,height=800',
            )
            myWindow.document.write(Z)
            myWindow.print()
            const mesaj = this.$store.getters.notificationSettings(res.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: JSON.stringify(res.data?.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    eFaturaAktar(kayitID) {
      this.$swal({
        title: 'Entegratöre Aktarılacak Eminmisiz?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aktar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$refs['card-actions'].showLoading = true
              store.dispatch('eFaturaAktar', kayitID)
                  .then((res, position = 'bottom-right') => {
                    this.$refs['card-actions'].showLoading = false
                    if (res.result.status == 200) {
                      store.dispatch('faturaListesi', {
                        uzunluk: this.firstPerPage,
                        baslangic: 0,
                        searcKey: this.firstFilter,
                        stateTur: 'acik',
                        faturaTuru: 1,
                      })
                          .then(res => {
                            this.firstTotalRows = res.toplamKayit
                            this.firstToplamKayit = res.data.length
                          })
                      this.aktarilanFaturaGet()
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Başarılı!',
                          text: res.result.errMsg,
                          icon: 'ThumbsDownIcon',
                          variant: 'success',
                        },
                      }, { position })
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
                  .catch(err => {
                    this.$refs['card-actions'].showLoading = false
                    if (err.result.errMsg) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: err.result.errMsg,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    secondPageChange(newVal) {
      const veri = newVal * this.secondPerPage - this.secondPerPage
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('proformaListesi', {
            uzunluk: this.secondPerPage,
            baslangic: veri,
            searchKey: this.secondFilter,
            durum: 1,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.secondToplamKayit = res.data.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    secondPerPageChange(newVal) {
      const veri = this.secondCurrentPage * newVal - newVal
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('proformaListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.secondFilter,
            durum: 1,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.secondToplamKayit = res.data.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    aktarilanFaturaGet() {
      store.dispatch('proformaListesi', {
        uzunluk: this.secondPerPage,
        searcKey: this.secondFilter,
        baslangic: 0,
        durum: 1,
      })
          .then(res => {
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.data.length
          })
    },
    goFatura(item) {
      router.push({
        name: 'proformaDetay',
        params: { id: item.proformaID },
      })
    },
    goProforma(item) {
      router.push({
        name: 'proformaDetay',
        params: { id: item.proformaID },
      })
    },
    firstPageChange(newVal) {
      const veri = newVal * this.firstPerPage - this.firstPerPage
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('proformaListesi', {
            uzunluk: this.firstPerPage,
            baslangic: veri,
            searchKey: this.firstFilter,
            durum: 0,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.firstToplamKayit = res.data?.length
            this.firstTotalRows = res.toplamKayit
          })
    },
    firstPerPageChange(newVal) {
      const veri = this.firstCurrentPage * newVal - newVal
      this.$refs['card-actions'].showLoading = true
      this.$store
          .dispatch('proformaListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.firstFilter,
            durum: 0,
          })
          .then(res => {
            this.$refs['card-actions'].showLoading = false
            this.firstToplamKayit = res.data?.length
            this.firstTotalRows = res.toplamKayit
          })
    },
    FirstOnFiltered(filteredItems) {
      this.firstTotalRows = filteredItems.length
      this.firstCurrentPage = 1
    },
    SecondOnFiltered(filteredItems) {
      this.secondTotalRows = filteredItems.length
      this.secondCurrentPage = 1
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'fatura-listesi'].showLoading = false
      }, 1000)
    },
  },
  created() {
    store.dispatch('proformaListesi', {
      uzunluk: this.firstPerPage,
      baslangic: 0,
      searcKey: this.firstFilter,
      durum: 0,
    })
        .then(res => {
          this.firstTotalRows = res.toplamKayit
          this.firstToplamKayit = res.data.length
        })
  },
  beforeDestroy() {
    store.commit({
      type: 'PROFORMA_LIST_ACTION',
      durum: 'remove',
    })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style scoped>
@media screen and (max-width: 3000px) {
  .per-page {
    width: 30% !important;
  }
}

@media screen and (max-width: 2000px) {
  .per-page {
    width: 35% !important;
  }
}

@media screen and (max-width: 1800px) {
  .per-page {
    width: 40% !important;
  }
}

@media screen and (max-width: 1600px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1100px) {
  .per-page {
    width: 55% !important;
  }
}

@media screen and (max-width: 1000px) {
  .per-page {
    width: 65% !important;
  }
}

@media screen and (max-width: 900px) {
  .per-page {
    width: 75% !important;
  }
}

@media screen and (max-width: 800px) {
  .per-page {
    width: 90% !important;
  }
}

.card-right-button {
  transform: translateY(7%) !important;
}

.card-title {
  transform: translateY(45%) !important;
}

.search-bar {
  width: 30% !important;
}

.search-input {
  border-color: #c3baba !important;
}

.fatura-turu {
  transform: translateY(50%) !important;
}

.col-8, .col-4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media screen and (max-width: 1140px) {
  .fatura-unvan-col-2 {
    transform: translateX(2%);
  }
}

.fatura-no {
  margin-bottom: 0.4rem !important;
}

.goruntule-btn {
  padding: 0.4rem;
}
</style>
